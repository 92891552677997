import { Container, TopLeft, BottomLeft, BottomRight, Hamburger } from './styles'
import { Logo } from './Logo'

export default function Overlay() {
  return (
    <Container>
      <TopLeft>
        <h1>
          Org.anically
          <br />
          D.isruptive
          <br />
          {/* <br />
          Labs */}
        </h1>
        <br />
        <p>Creators of Languages | Intelligences | Worlds</p>
        <p className="shouldHide" style={{ position: 'relative', left: '120px', bottom: '-40px', fontSize: '11pt', color: 'white', fontStyle: 'italic' }}>
          Spotlight Projects:
        </p>
        <a className="shouldHide" href="neuroQL" target="_blank">
          <img
            src="neuroQL_logo_transparent.png"
            alt="NeuroQL"
            style={{ position: 'relative', left: '-20px', bottom: '40px', height: '300px', width: '400px', zIndex: '2' }}
          />
        </a>
      </TopLeft>
      <BottomLeft>
        <p>Abbraccia Ogne Speranza Voi Ch' Entrate</p>
      </BottomLeft>
      <BottomRight>
        We operate in the areas of Programming Languages, AI, VR and Tools.
        <br />
        <br />
        We take on "improbable" R&D projects, training your engineers beyond the state of art.
      </BottomRight>
      <Hamburger>
        Book a project:{' '}
        <a href="mailto:research@orgdlabs.com" style={{ color: 'white' }}>
          research@orgdlabs.com
        </a>
        <p className="shouldHide" style={{ position: 'relative', top: '110px', fontSize: '11pt', color: 'white', fontStyle: 'italic' }}>
          Our News:
        </p>
        <a className="shouldHide" href="https://arxiv.org/abs/2303.07146" target="_blank">
          <img src="arxiv-logo-one-color-white.png" alt="NeuroQL" style={{ position: 'relative', top: '100px', height: '40px', width: '100px', zIndex: '2' }} />
        </a>
        <a
          className="shouldHide"
          href="https://arxiv.org/pdf/2303.07146.pdf"
          target="_blank"
          style={{ position: 'relative', top: '100px', left: '260px', width: '140px', fontSize: '11pt', color: 'white', fontStyle: 'italic' }}>
          Read our NeuroQL Paper on Arxiv
        </a>

        {/* <div />
        <div />
        <div /> */}
          <p className="shouldHide" style={{position: 'relative', top: '160px', fontStyle: 'italic'}}>
            Latest Projects:
          </p>
          <a className="shouldHide" style={{position: 'relative', top: '250px', height: '300px', width: '400px'}} href="https://arxiv.org/abs/2501.00169" target="_blank">
            <img
              src="DeepLL.png"
              alt="DeepLL"
              style={{position: 'relative', top: '-89px', left: '20px', height: '100px', width: '200px'}}
            />
          </a>
      </Hamburger>
      
      <Logo />
    </Container>
  )
}
